module.exports = {
  blogPostDir: 'posts', // The name of directory that contains your posts.
  blogAuthorDir: 'authors', // The name of directory that contains your 'authors' folder.
  blogAuthorId: 'remco', // The default and fallback author ID used for blog posts without a defined author.
  siteTitle: 'Remco Rakers', // Site title.
  siteTitleAlt: 'Remco Rakers', // Alternative site title for SEO.
  siteLogo: '/logos/logo-48.png', // Logo used for SEO and manifest. e.g. "/logos/logo-1024.png",
  siteUrl: 'https://remcorakers.nl', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-casper/.
  siteDescription: 'Software developer and team lead.', // Website description used for RSS feeds/meta description tag.
  // siteCover: "/images/cover.jpg", // Optional, the cover image used in header for home page. e.g: "/images/blog-cover.jpg",
  siteNavigation: false, // If navigation is enabled the Menu button will be visible
  siteRss: '/rss.xml', // Path to the RSS file.
  siteRssAuthor: 'Remco Rakers', // The author name used in the RSS file
  // siteFBAppID: "1825356251115265", // optional, sets the FB Application ID for using app insights
  sitePaginationLimit: 10, // The max number of posts per page.
  googleAnalyticsID: 'UA-10799314-1', // GA tracking ID.
  disqusShortname: 'remcorakers', // enables Disqus comments, visually deviates from original Casper theme.
  siteSocialUrls: ['https://github.com/remcorakers', 'https://linkedin.com/in/remcorakers'],
  postDefaultCategoryID: 'code', // Default category for posts.
  // Links to social profiles/projects you want to display in the navigation bar.
  userLinks: [
    {
      label: 'GitHub',
      url: 'https://github.com/remcorakers',
      iconClassName: 'fa fa-github', // Disabled, see Navigation.jsx
    },
    {
      label: 'LinkedIn',
      url: 'https://linkedin.com/in/remcorakers',
      iconClassName: 'fa fa-linkedin', // Disabled, see Navigation.jsx
    },
  ],
  // Copyright string for the footer of the website and RSS feed.
  copyright: {
    label: 'Remco Rakers', // Label used before the year
    // year: "2018" // optional, set specific copyright year or range of years, defaults to current year
    // url: "" // optional, set link address of copyright, defaults to site root
  },
  themeColor: '#c62828', // Used for setting manifest and progress theme colors.
  backgroundColor: '#e0e0e0', // Used for setting manifest background color.
  promoteGatsby: false, // Enables the GatsbyJS promotion information in footer.
};
